<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
        <div class="wrapper wrapper--full wrapper--grey">
            <div class="wrapper wrapper--grey">

                <div class="advertise">
                    <h1>Manage Your Ad</h1>
                    <p class="advertise__auth--mobileonly">This section allows private advertisers to manage their ad.</p>
                    <div class="advertise__auth">
                        <div class="advertise__auth_wrapper">
                            <p class="advertise__auth--desktoponly">This section allows private advertisers to manage their ad.</p>
                            <div class="advertise__auth_panel">
                                <h3>Enter your Password</h3>
                                <form action="javascript:void(0)">
                                    <input type="password" data-cy="password" v-model="password">
                                    <input type="submit" data-cy="submit" value=" " @click="authenticate()">
                                    <div v-if="showError" class="form-error-2">{{ errorMessage }}</div>
                                </form>
                                <h4 class="forgot">
                                    <router-link :to="{name: 'advertise-forgot-password'}">Forgot your Password?</router-link>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from "@/components/partials/Breadcrumbs"
    import {mapMutations, mapState} from "vuex";
    import Loading from 'vue-loading-overlay';

    export default {
        name: "AdvertiseAuth",
        components: {
            MainHeader,
            MainFooter,
            Breadcrumbs,
            Loading
        },
        data: function () {
            return {
                title: "Used Cars, Cars in Ireland, Electric Cars, 7 Seaters, SUV’s | Terrific.ie",
                description: "Terrific.ie details used cars for sale in Ireland including electric cars, 7 seaters and SUV’s.",
                keywords: "Used Cars, Cars for Sale, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters,  Land Rover, People Carriers",
                robots: "all",
                password: "",
                showError: false,
                errorMessage: ''
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.updateBreadcrumb();
            // this.getAd();
        },
        mounted(){
         this.checkBypass();
         this.updateBreadcrumb();
        },
        computed:{
        ...mapState([
                'isLoading'
            ])
        },
        methods: {
            ...mapMutations([
                'setIsLoading'
            ]),
            checkBypass(){
                if(this.$route.query.token){
                    this.password = atob(this.$route.query.token);
                    this.authenticate();
                }
            },
            updateBreadcrumb(){
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Login');
                this.$store.commit('breadcrumbs/setFirstLevelName',  "Advertise");
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/advertise/landing');
                this.$store.commit('breadcrumbs/setLevelOne',  true);
            },
            authenticate(){

                if(this.password.trim().length ==0){
                    this.errorMessage = "The password can't be empty";
                    this.showError = true;
                    return false;
                }

                this.setIsLoading(true);

                this.axios.post(process.env.VUE_APP_API_URL + '/api/advertise/auth/login', {
                        password: this.password
                    })
                    .then(response => {

                        if(typeof response.data.error !== 'undefined'){
                            this.errorMessage = response.data.error;
                            this.showError = true;
                        }else{
                            let action = this.$route.query.action;

                            sessionStorage.setItem('adId', response.data.ad_id);
                            sessionStorage.setItem('key', this.password);

                            switch (action){
                                case 'edit' :
                                    sessionStorage.setItem('editMode', true);
                                    this.$router.push('/advertise/private');
                                    break;
                                case 'delete' :
                                    this.$router.push('/advertise/confirm');
                                    break;
                                case 'renew' :
                                    this.$router.push('/advertise/confirm');
                                    break;
                                case 'photos' :
                                    this.$router.push('/advertise/private?step=2');
                                    break;
                            }

                        }

                        this.setIsLoading(false);

                    })
                    .catch(error => {
                        console.error(error);
                        this.setIsLoading(false);
                    });
            }
        }
    }
</script>

<style scoped>
</style>
