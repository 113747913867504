<template>

    <div class="wrapper">
        <div class="breadcrumbs">
            <ul>
                <li><router-link :to="{path: '/'}">Home</router-link></li>
                <li v-if="$store.state.breadcrumbs.levelOne"><router-link :to="{path: $store.state.breadcrumbs.pathLevelOne}">{{ $store.state.breadcrumbs.firstLevelName }}</router-link></li>
                <li v-if="$store.state.breadcrumbs.levelTwo"><router-link :to="{path: $store.state.breadcrumbs.pathLevelTwo}">{{ $store.state.breadcrumbs.secondLevelName }}</router-link></li>
                <li v-if="$store.state.breadcrumbs.levelThree"><router-link :to="{path: $store.state.breadcrumbs.pathLevelThree}">{{ $store.state.breadcrumbs.thirdLevelName }}</router-link></li>
                <li v-if="$store.state.breadcrumbs.levelFour"><router-link :to="{path: $store.state.breadcrumbs.pathLevelFour}">{{ $store.state.breadcrumbs.fourthLevelName }}</router-link></li>
                <li class="active">{{ $store.state.breadcrumbs.leafLevelName }}</li>
            </ul>
        </div>
    </div>

</template>

<script>
    export default ({
        name: "Breadcrumbs",
        components: {
        },
        props: ['param', 'slug'],
        watch: {
            '$route.params.make': function () {
                this.updateBreadCrumb()
            }
        },
        data: function () {
            return {
                adSlug: this.slug ? this.slug.split("/").pop() : '',
            }
        },
        serverPrefetch() {
            this.updateBreadCrumb();
        },
        mounted(){
            this.updateBreadCrumb();
        },
        computed:{
            breadcrumb(){
                return this.$store.state.breadcrumbs.firstLevelName;
            },
            car() {
                return this.$store.state.ads.ad[this.getRealSlug];
            },
            getRealSlug(){
                let queryString = '';

                if(this.$route.query.deposit !== 'undefined' && this.$route.query.deposit > 0){
                    queryString = queryString + '&deposit=' + this.$route.query.deposit;
                }
                if(this.$route.query.priceFrom !== 'undefined' && this.$route.query.priceFrom > 0){
                    queryString = queryString + '&priceFrom=' + this.$route.query.priceFrom;
                }
                if(this.$route.query.priceTo !== 'undefined' && this.$route.query.priceTo > 0){
                    queryString = queryString + '&priceTo=' + this.$route.query.priceTo;
                }

                if(queryString.length > 0 && queryString.charAt(0) == '&'){
                    queryString = '?' + queryString.substring(1);
                }
                return this.$router.currentRoute.params.slug.split('/r/')[1] + queryString;
            }
        },
        methods: {
            updateBreadCrumb(){
                let meta = this.$route.meta;
                let attribute = this.$router.currentRoute.name;
                let emptyMeta = Object.keys(meta).length === 0 && meta.constructor === Object;

                if(!emptyMeta && typeof  meta[attribute].title !== 'undefined'){
                    //According to the route's name, handle the breadcrumb data
                    switch (attribute) {
                        case 'car-reviews' :
                        case 'car-reviews-paginated' :
                            this.$store.commit('breadcrumbs/setLeafLevelName',  meta[attribute].title.charAt(0) + meta[attribute].title.slice(1));
                            this.handleCarReviews();
                            break;
                        case 'news-article':
                            this.handleNews();
                            break;
                        case 'review':
                            break;
                        case 'used-cars':
                            this.handleUsedCars();
                            break;
                        case 'vehicle-detail':
                        case 'vehicle-detail-adv':
                        case 'vehicle-detail-adv-print':
                            this.handleVehicleDetail();
                            break;
                        case 'dealer':
                        case 'dealer-2':
                        case 'dealer-finance':
                        case 'dealer-reviews':
                        case 'dealer-directions':
                            break;
                        default:
                            this.resetVars();
                            this.$store.commit('breadcrumbs/setLeafLevelName',  meta[attribute].title);
                    }

                }
            },
            resetVars(){
                this.$store.commit('breadcrumbs/setLevelOne',  false);
                this.$store.commit('breadcrumbs/setLevelTwo',  false);
                this.$store.commit('breadcrumbs/setLevelThree',  false);
                this.$store.commit('breadcrumbs/setLevelFour',  false);
            },
            handleUsedCars(){
            },
            handleVehicleDetail(){
                let make = this.car ? this.car.make : '';
                let model = this.car ?  this.car.model : '';
                let year =  this.car ? this.car.reg_year : '';

                this.$store.commit('setInternalSource',  false);
                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setFirstLevelName',  "Used Cars");
                this.$store.commit('breadcrumbs/setLevelTwo',  true);

                this.$store.commit('breadcrumbs/setSecondLevelName',  make);
                this.$store.commit('breadcrumbs/setLevelThree',  true);
                this.$store.commit('breadcrumbs/setThirdLevelName',  model);

                this.$store.commit('breadcrumbs/setLevelFour',  true);
                this.$store.commit('breadcrumbs/setFourthLevelName',  year);
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Details');

                this.$store.commit('breadcrumbs/setPathLevelOne',  '/used-cars');
                this.$store.commit('breadcrumbs/setPathLevelTwo',  '/used-cars/makes-' + this.stringToSlug(make));
                this.$store.commit('breadcrumbs/setPathLevelThree',  '/used-cars/makes-' + this.stringToSlug(make) + '/models-' + this.stringToSlug(model));
                this.$store.commit('breadcrumbs/setPathLevelFour',  '/used-cars/makes-' + this.stringToSlug(make) + '/models-' + this.stringToSlug(model) + '/yearfrom-' + year + '/yearto-' + year);
            },
            handleCarReviews(){
                this.resetVars();
                let params = this.$router.currentRoute.params;
                if(typeof params.make !== 'undefined'){
                    this.$store.commit('breadcrumbs/setLevelOne',  true);
                    this.$store.commit('breadcrumbs/setLevelTwo',  false);
                    this.$store.commit('breadcrumbs/setLevelThree',  false);
                    this.$store.commit('breadcrumbs/setLeafLevelName',  this.formattedLeafName(params.make));
                }else if(typeof params.id !== 'undefined'){
                    this.$store.commit('breadcrumbs/setLevelOne',  true);
                    this.$store.commit('breadcrumbs/setLevelTwo',  true);
                    this.$store.commit('breadcrumbs/setLevelThree',  false);
                    // this.$store.commit('breadcrumbs/setLeafLevelName',  params.id);
                }else{
                    this.$store.commit('breadcrumbs/setLevelOne',  false);
                    this.$store.commit('breadcrumbs/setLevelTwo',  false);
                    this.$store.commit('breadcrumbs/setLevelThree',  false);
                }
            },
            formattedLeafName(make){
                const words = make.split(" ");
                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                }
                return words.join(" ");
            },
            handleNews(){
                this.resetVars();
                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setLevelTwo',  false);
                this.$store.commit('breadcrumbs/setLevelThree',  false);
            },
            stringToSlug(str) {
                if(typeof str !== 'string'){
                    return '';
                }

                str = str.replace(/^\s+|\s+$/g, ''); // trim
                str = str.toLowerCase();

                // remove accents, swap ñ for n, etc
                let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
                let to = "aaaaeeeeiiiioooouuuunc------";
                for (let i = 0, l = from.length; i < l; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                    .replace(/\s+/g, '+') // collapse whitespace and replace by -
                    .replace(/-+/g, '+'); // collapse dashes

                return str;
            },
        }
    })
</script>

<style scoped>
</style>
